.tabContainer {
    .newPostBtn {
        float: right;
        display: block;
        margin: 0.5rem 0rem;
    }
    .searchBox {
        float: right;
        vertical-align:top;
    }
    .messageContainer {
        clear: both;
        display: block;

        .ui-accordion__title {
            margin-top: 1.6rem;
        }

    }

    .ms-DetailsRow {
        height: 4.4rem;
    }

    .ms-CommandBar {
        background-color: transparent;
        height: 2rem;
    }

    .results {
        font-weight: bold;
        margin: 1rem 1rem 1rem 2rem;
    }
}

@media only screen and (min-width : 1000px) {
    .taskModule {
        .formContainer {
            .formContentContainer {
                width: 53%;
                float: left;
                display: inline;
            }

            .adaptiveCardContainer {
                width: 45%;
                display: inline;
                float: left;

                .ac-container {
                    margin-left: 0rem !important;
                }
            }
        }
    }
}

@media only screen and (min-width : 826px) and (max-width : 999px) {
    .taskModule {
        .formContainer {
            .formContentContainer {
                width: 48%;
                float: left;
                display: inline;
            }

            .adaptiveCardContainer {
                width: 45%;
                display: inline;
                float: left;

                .ac-container {
                    margin-left: 0rem !important;
                }
            }
        }
    }
}

@media screen and (min-width : 0px) and (max-width: 825px) {
    .taskModule {
        .formContainer {
            .formContentContainer {
                width: 100%;
                display: block;
            }

            .adaptiveCardContainer {
                width: 100% !important;
                display: block !important;
                margin: 2rem auto;

                .ac-container {
                    margin: 0rem auto !important;
                }
            }
        }
    }
}